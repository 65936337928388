




















































































































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Ref} from 'vue-property-decorator';
import {BV_OptionItem} from '@/models';
import AXIOS from '../services/axios-wrapper';
import hrBoardService from '../services/hr.service';
import {HrBoardArticle} from '@/model-hr';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
  },
})
export default class HrBoardArticleRead extends GeneralUtil {
  @Prop(Number) readonly boardArticleId!: number;

  dto: HrBoardArticle | null = null;

  options_jobType: BV_OptionItem[] = [
    {value: '신입', text: '신입'},
    {value: '경력', text: '경력'},
    {value: '신입경력', text: '신입/경력'},
  ];

  created() {
    this.fetchData();
  }

  getJobTypeText(jobType: string): string {
    console.log(jobType);
    let mojip2Options = this.options_jobType.filter(x => x.value === jobType);
    if (mojip2Options.length == 0) {
      return jobType;
    }
    return mojip2Options[0].text;
  }

  goHrRequestPage() {
    this.$router.push(`/p/HrRequest/${this.boardArticleId}`);
  }

  async fetchData() {
    try {
      this.showLoadingModal();
      const response = await hrBoardService.getArticleDetails(this.boardArticleId, 'HrBoardArticleDetail');
      this.dto = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.hideLoadingModal();
    }
  }
}
