








import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import HrBoardArticleRead from '@/components/HrBoardArticleRead.vue';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
    HrBoardArticleRead,
  },
})
export default class HrNoticePageRead extends GeneralUtil {
  notice_breadcrumb = {
    toplink: 'HOME',
    prevlink: '인재채용',
    present: '채용공고',
  };
  mounted() {
    this.$store.dispatch('Display/changeName', {name: '채용공고'});
  }
}
