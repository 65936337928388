import AXIOS from './axios-wrapper';
import {BOARD_ID, SORT_ASC_DESC, SORT_FIELD} from '@/util/BoardConst';
import {HR_ARTICLE_PROJETION} from '@/util/HrBoardConst';
import {
  HrBoardArticle,
  HrBoardArticleResponse,
  HrRequestDetail,
  HrRequestExtraInfo,
  HrRequestResponse,
  HrRequestSummary,
} from '@/model-hr';

class HrService {
  constructor() {
    console.log('creating new instance of HrBoardService');
  }

  async getArticleList(
    bid: BOARD_ID,
    pageNumber = 1,
    pageSize = 10,
    aProjection: HR_ARTICLE_PROJETION = 'HrBoardArticleSummary',
    sortField: SORT_FIELD = 'id',
    sortAscDesc: SORT_ASC_DESC = 'desc',
  ): Promise<HrBoardArticleResponse> {
    const req_uri = `/HrBoardArticleRepo?projection=${aProjection}&boardInfo.id=${bid}&page=${
      pageNumber - 1
    }&size=${pageSize}&sort=${sortField},${sortAscDesc}`;

    const response = await AXIOS.get<HrBoardArticleResponse>(req_uri);
    return response.data;
  }

  async getArticleDetails(aid: number, projection: string): Promise<HrBoardArticle> {
    await AXIOS.get<void>(`/HrBoardArticleRepo/search/UpdateReadCount?id=${aid}`);
    const response = await AXIOS.get<HrBoardArticle>(`/HrBoardArticleRepo/${aid}?projection=${projection}`);

    return response.data;
  }

  async getHrRequest(aid: number): Promise<HrRequestDetail> {
    const response = await AXIOS.get<HrRequestDetail>(`/HrRequestRepo/${aid}?projection=HrRequestDetail`);

    return response.data;
  }

  async getHrRequestSearch(searchQuery: string): Promise<HrRequestResponse> {
    const response = await AXIOS.get<HrRequestResponse>(`/HrRequestRepo?projection=HrRequestSummary${searchQuery}`);

    return response.data;
  }

  async postHrRequestSummary(hrRequest: HrRequestSummary): Promise<HrRequestDetail> {
    const response = await AXIOS.post<HrRequestDetail>(`/HrRequestRepo`, hrRequest);

    return response.data;
  }

  async updateHrRequestDetail(hrRequest: HrRequestDetail): Promise<HrRequestDetail> {
    const response = await AXIOS.patch<HrRequestDetail>(`/HrRequestRepo/${hrRequest.id}`, hrRequest);

    return response.data;
  }

  async postHrRequestExtra(hrRequestExtra: HrRequestExtraInfo): Promise<HrRequestExtraInfo> {
    const response = await AXIOS.post<HrRequestExtraInfo>(`/HrRequestExtraRepo`, hrRequestExtra);
    return response.data;
  }

  async updateHrRequestExtra(hrRequestExtra: HrRequestExtraInfo): Promise<HrRequestExtraInfo> {
    const response = await AXIOS.put<HrRequestExtraInfo>(`/HrRequestExtraRepo/${hrRequestExtra.id}`, hrRequestExtra);

    return response.data;
  }

  async deleteHrRequestExtra(id: number): Promise<HrRequestExtraInfo> {
    const response = await AXIOS.delete<HrRequestExtraInfo>(`/HrRequestExtraRepo/${id}`);

    return response.data;
  }

  async download(id: number): Promise<any> {
    return await AXIOS.get<any>(`/hr/files/download/${id}`, {responseType: 'blob'});
  }
}

// Export a singleton instance in the global namespace
const hrService = new HrService();

export default hrService;
